import { Component, OnInit } from '@angular/core';
import { adevertise } from 'src/app/constant/auto/adevertise.constant';

@Component({
  selector: 'app-admission',
  templateUrl: './admission.component.html',
  styleUrls: ['./admission.component.css']
})
export class AdmissionComponent implements OnInit {

  readonly advertiseList = adevertise;
  constructor() { }

  ngOnInit() {
  }

}
