<!-- Page Content -->
<div class="container">
  <div class="row">
    <!--     <div class="col-lg-12">

            <p class="">
                Shikshan prasarak Mandal's
              </p>
              <h1 class="" style="margin-top:-20px;">College of Pharmacy (poly), Akluj</h1>
    </div> -->
    <!-- Post Content Column -->
    <div class="col-lg-8" style="flex: 1 1 auto; padding: 1.25rem;">

      <!-- Author -->
      <!--       <hr> -->
      <!-- Preview Image -->
      <img class="img-fluid rounded" src="./assets/img/college/banner.jpg" alt="">
      <hr>
      <!-- Post Content -->

      <div class="card">
        <div class="card-header">
          <h5>Vision</h5>
        </div>
        <div class="card-body">
          <p class="card-text">
            To become one of the leading institute of higher education with a view of global competencies among the
            students by use of
            technologies, disciplines, resources and even attitudes and finally contributing to national developement.
          </p>
        </div>
      </div>
      <br>
      <div class="card">
        <div class="card-header">
          <h5>Mission</h5>
        </div>
        <div class="card-body">
          <p class="card-text">
            To impart higher quality Pharmaceutical education and practice to the wards of farmers, workers, labourers
            and rural area
            and to meet healthcare needs of community in Maharashtra.
          </p>
        </div>
      </div>
      <br>
      <div class="card">
        <div class="card-header">
          <h5>Admission Notification</h5>
        </div>
        <div class="card-body">
          <ul>
            <li *ngFor="let adv of advertiseList">
              <a target="_blank" href="{{adv.filename}}">{{adv.displayText}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Sidebar Widgets Column -->
    <div class="col-md-4">



      <!-- Side Widget -->
      <div class="card my-4">
        <h5 class="card-header">Motto</h5>
        <div class="card-body text-center">
          <img alt="Founder" src="./assets/img/college/logo.png" class="img-responsive"
            style="max-height:134px;margin: 0 auto;border-radius: 0px 10px;border: 2px solid;box-shadow: 10px">
          <p> <strong>"Tewo Sada Dnyanamaya pradeep"</strong></p>
          <small class="text-muted">Let the life save forever</small>
        </div>
      </div>

      <!-- Categories Widget -->
      <div class="card my-4">
        <h5 class="card-header">Important links</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <ul class="list-unstyled mb-0">
                <li *ngFor="let link of importantLinks">
                  <a href="{{link.filename? link.filename : link.link}}" target="_blank">{{link.displayText}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card my-4">
        <h5 class="card-header">Academic</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <ul class="list-unstyled mb-0">
                <li *ngFor="let link of academicLinks">
                  <a href="{{link.filename? link.filename : link.link}}" target="_blank">{{link.displayText}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card my-4">
        <h5 class="card-header">News Letter</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <ul class="list-unstyled mb-0">
                <li *ngFor="let link of newsletter">
                  <a href="{{link.filename? link.filename : link.link}}" target="_blank">{{link.displayText}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  <!-- /.row -->

</div>
<!-- /.container -->