<div class="container">

  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Admission</h5>
      <hr>
      <br>
      <div class="row">
        <div class="col-lg-12">

          <ul class="list-group">
            <li class="list-group-item" *ngFor="let link of advertiseList; let i=index">
              <a [href]="link.filename" target="_blank">{{link.displayText}}</a>
              <app-pdf-viewer [title]="link.displayText" [url]="link.filename" [id]="'link'+i"></app-pdf-viewer>
            </li>
          </ul>

        </div>
      </div>

    </div>
  </div>
</div>