export const adevertise=[
  {
    "displayText": "Punyanagari Advertise",
    "filename": "./assets/documentation/adv/Punyanagari Advertise.jpg",
    "link": ""
  },
  {
    "displayText": "Sakal Advertise",
    "filename": "./assets/documentation/adv/Sakal Advertise.jpg",
    "link": ""
  },
  {
    "displayText": "Schedule ACAP",
    "filename": "./assets/documentation/adv/Schedule ACAP.jpg",
    "link": ""
  },
  {
    "displayText": "Schedule IL",
    "filename": "./assets/documentation/adv/Schedule IL.jpg",
    "link": ""
  }
]